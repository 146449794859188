import { Location } from "history";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { Button } from "../Common/Button";
import { useOutsideAlerter } from "../../utils/outsideAlerter";

import {
  actionLink,
  ActionList,
  DropdownWrapper,
  TableActionWrapper,
} from "./__styles__/DropdownMenu";

export interface ActionsProps {
  label: string | JSX.Element;
  href?: string;
  to?: Partial<Location>;
  download?: boolean | string;
  target?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  variant?: Exclude<
    NonNullable<Parameters<typeof actionLink>[0]>["variant"],
    "disabled"
  >;
  disabled?: boolean;
  hidden?: boolean;
  closeOnClick?: boolean;
  track?: () => void;
}

export type DropdownPosition =
  | "below-right"
  | "below-left"
  | "above-right"
  | "above-left";

const getPositionStyles = (position: DropdownPosition, refHeight: number) => {
  if (position === "below-right") {
    return {
      marginTop: "8px",
    };
  } else if (position === "below-left") {
    return {
      right: "0px",
      marginTop: "8px",
    };
  } else if (position === "above-right") {
    return {
      transform: `translate(${0}%, -${100}%) translate(0px, -${
        refHeight + 8
      }px)`,
    };
  } else if (position === "above-left") {
    return {
      right: "0px",
      transform: `translate(${0}%, -${100}%) translate(0px, -${
        refHeight + 8
      }px)`,
    };
  }
  return {};
};

interface DropdownMenu {
  actions: Array<ActionsProps>;
  buttonText?: string;
  disabled?: boolean;
  hidden?: boolean;
  position?: DropdownPosition;
  actionListWidth?: string;
  customButton?: React.FC<{
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    text?: string;
  }>;
  scrollIntoView?: boolean;
  isTableAction?: boolean;
}

const DropdownMenu = ({
  actions,
  buttonText = "",
  disabled = false,
  hidden = false,
  position = "below-left",
  customButton,
  scrollIntoView = false,
  actionListWidth = "auto",
  isTableAction = false,
  ...props
}: DropdownMenu) => {
  const wrapperRef = useRef(null as null | HTMLDivElement);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [refHeight, setRefHeight] = useState(0);

  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setIsDropdownOpen(false),
  });

  useLayoutEffect(() => {
    if (wrapperRef.current && wrapperRef.current.clientHeight) {
      const height = wrapperRef.current.clientHeight;
      setRefHeight(height);
    }
  });

  const actionsRef = useRef(null as null | HTMLDivElement);

  useLayoutEffect(() => {
    if (scrollIntoView && isDropdownOpen) {
      const actionList = actionsRef?.current;
      const documentBody = document.body;

      if (actionList && documentBody && actionList instanceof HTMLElement) {
        if (
          actionList.getBoundingClientRect().bottom + actionList.offsetTop >
          documentBody.getBoundingClientRect().height
        ) {
          actionList?.scrollIntoView({
            block: "end",
            behavior: "smooth",
            inline: "nearest",
          });
        }
      }
    }
  }, [scrollIntoView, isDropdownOpen, actionsRef]);

  const onActionClick = ({
    disabled,
    onClick,
    closeOnClick = true,
    track,
  }: {
    disabled?: boolean;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
    closeOnClick?: boolean;
    track?: () => void;
  }) => {
    if (disabled) {
      return;
    }

    if (track) {
      track();
    }
    if (onClick) {
      onClick();
    }
    if (closeOnClick) {
      setIsDropdownOpen(false);
    }
  };

  const DropdownOption = ({ action }: { action: ActionsProps }) => {
    const className = actionLink({
      variant: action.disabled ? "disabled" : action.variant,
    });

    const onClick = () =>
      onActionClick({
        disabled: action.disabled,
        onClick: action.onClick,
        closeOnClick: action.closeOnClick,
        track: action.track,
      });

    const hotkeyRef = useHotkeys<HTMLDivElement>(
      ["space", "return"],
      () => {
        onClick();
      },
      { preventDefault: true }
    );

    if (action.to) {
      return (
        <Link
          className={className}
          to={action.to}
          onClick={onClick}
          target={action.target}
          tabIndex={0}
        >
          {action.label}
        </Link>
      );
    } else if (action.href) {
      return (
        <a
          className={className}
          href={action.href}
          onClick={onClick}
          download={action.download}
          target={action.target}
          tabIndex={0}
        >
          {action.label}
        </a>
      );
    }
    return (
      <div className={className} onClick={onClick} tabIndex={0} ref={hotkeyRef}>
        {action.label}
      </div>
    );
  };

  const dropdown = (
    <DropdownWrapper ref={wrapperRef} hidden={hidden}>
      {customButton ? (
        customButton({
          onClick: () => {
            setIsDropdownOpen(!isDropdownOpen);
          },
          disabled,
          text: buttonText,
        })
      ) : (
        <Button
          size="small"
          onClick={e => {
            e.preventDefault();
            setIsDropdownOpen(!isDropdownOpen);
          }}
          styleVariant={!buttonText ? "hoverOutline" : "secondary"}
          disabled={disabled}
          {...{ "data-testid": "actions", ...props }}
          leftIconName={!buttonText ? "more-horizontal" : undefined}
          style={{ minWidth: "unset" }}
        >
          {buttonText}
        </Button>
      )}

      {isDropdownOpen && (
        <ActionList
          style={getPositionStyles(position, refHeight)}
          css={{ $$actionListWidth: actionListWidth }}
          data-testid="actions-list"
          ref={actionsRef}
        >
          {actions
            .filter(action => !action.hidden)
            .map((action, i) => {
              return <DropdownOption action={action} key={i} />;
            })}
        </ActionList>
      )}
    </DropdownWrapper>
  );

  if (isTableAction) {
    return <TableActionWrapper>{dropdown}</TableActionWrapper>;
  }
  return dropdown;
};

export default DropdownMenu;
